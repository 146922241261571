import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { StyledIntroText, StyledReferralLogotype, StyledRoot } from './styles';

export const ReferralWelcome = () => {
  const { t } = useTranslation('auth-page');
  return (
    <StyledRoot>
      <StyledReferralLogotype />
      <Stack spacing={1}>
        <StyledIntroText>
          <Trans ns="auth-page" i18nKey="components.welcome.referral.title" components={{ br: <br /> }} />
        </StyledIntroText>
        <Typography variant="Body/medium" color="neutral.700">
          <Trans
            ns="auth-page"
            i18nKey="components.welcome.referral.description"
            components={{
              br: <br />,
              sum: <Typography variant="Body/medium" color="#1e2f96" fontWeight={500} />,
            }}
            values={{
              sum: t('components.welcome.referral.sum'),
            }}
          />
        </Typography>
      </Stack>
    </StyledRoot>
  );
};
