import { useTranslation } from 'react-i18next';
import { css, Paper, Stack, styled, Typography } from '@mui/material';

import doneImage from '@/shared/assets/image/registration/done.png';

const StyledWideCardRoot = styled(Paper)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: stretch;
  flex: 0 1 auto;
  justify-content: center;
  position: relative;

  border-radius: 16px;
  padding: 32px 40px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('tablet')} {
      box-shadow: none;
    }
  `}
`;

const StyledCardRoot = styled(StyledWideCardRoot)`
  max-width: 408px;
`;

export const StyledDoneImage = styled('picture')`
  width: 120px;
  height: 120px;
  margin-bottom: 40px;
  background: url(${doneImage}) no-repeat;
  background-size: contain;

  ${({ theme }) => css`
    ${theme.breakpoints.down('tablet')} {
      width: 120px;
      height: 120px;
    }
  `}
`;

export const ReferralRequestCompletedCard = () => {
  const { t } = useTranslation('referral-request-completed-page');

  return (
    <StyledCardRoot>
      <Stack spacing={5} alignItems="center">
        <StyledDoneImage />
        <Stack spacing={3}>
          <Typography variant="Headline/small" align="center">
            {t('title')}
          </Typography>
          <Typography variant="Body/medium" align="center">
            {t('description')}
          </Typography>
        </Stack>
      </Stack>
    </StyledCardRoot>
  );
};
