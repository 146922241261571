import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, css, FormControl, Paper, Stack, styled, Typography } from '@mui/material';

import { RoundedButton } from '@/components/buttons';
import { useGlobalErrorStore } from '@/components/dialogs';
import { CustomFormLabel, Form, RHFMaskedPhoneTextField } from '@/components/form-controls';
import { ReferralPaths } from '@/shared/lib/react-router';
import { Yup } from '@/shared/lib/yup';

import { useSendRegistrationRequest } from './hooks';

const StyledWideFormRoot = styled(Paper)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  position: relative;

  padding: 32px 40px 40px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('tablet')} {
      box-shadow: none;
      padding: 32px 40px 40px;
    }
  `}
`;

const StyledFormRoot = styled(StyledWideFormRoot)`
  max-width: 408px;
`;

const StyledFormTitle = styled(Typography)`
  color: #1e2f96;
`;

// ----------------------------------------------------------------------

type FormValues = { firstName: string; lastName: string; phone: string; tin: string; referralCode: string };

const formSchema = Yup.object().shape({
  phone: Yup.string().isPossiblePhoneNumber().required('Обязательное поле'),
  referralCode: Yup.string(),
});

export const ReferralForm = () => {
  const { t, i18n } = useTranslation('referral-page');

  const navigate = useNavigate();

  const referralCodeFromQuery = useSearchParam('referralCode') || '';
  const langFromQuery = useSearchParam('lang') || '';

  useEffect(() => {
    if (langFromQuery) {
      i18n.changeLanguage(langFromQuery);
    }
  }, [langFromQuery, i18n]);

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(formSchema),
    defaultValues: {
      phone: '',
      referralCode: referralCodeFromQuery,
    },
  });

  const {
    formState: { isSubmitting },
    handleSubmit: wrapHandleSubmit,
  } = methods;

  const { setGlobalError } = useGlobalErrorStore();

  const { mutateAsync: sendRegistrationRequest } = useSendRegistrationRequest();

  const handleSubmit = async (formData: FormValues) => {
    try {
      const { phone, referralCode } = formData;

      await sendRegistrationRequest({
        payload: {
          phone,
          referral_code: referralCode,
        },
      });
      navigate(ReferralPaths.REFERRAL_REQUEST_COMPLETED_PATH);
    } catch (error) {
      setGlobalError(error);
    }
  };

  return (
    <StyledFormRoot>
      <Stack spacing={5}>
        <Stack spacing={2}>
          <StyledFormTitle variant="Title/Bold/large">{t('form.title')}</StyledFormTitle>
          <Typography variant="Body/medium" color="neutral.700">
            {t('form.description')}
          </Typography>
        </Stack>

        <FormProvider {...methods}>
          <Form onSubmit={wrapHandleSubmit(handleSubmit)} isDisabled={isSubmitting}>
            <Stack spacing={3} mb={3}>
              <FormControl fullWidth>
                <CustomFormLabel isRequired htmlFor="phone">
                  {t('form.labels.phone')}
                </CustomFormLabel>
                <RHFMaskedPhoneTextField
                  fullWidth
                  name="phone"
                  id="phone"
                  autoComplete="phone"
                  variant="filled"
                  inputProps={{ 'data-testid': 'referral-phone-input' }}
                />
              </FormControl>
            </Stack>
            <Box display="flex" justifyContent="center">
              <RoundedButton
                sx={{ bgcolor: '#1E2F96' }}
                loading={isSubmitting}
                type="submit"
                variant="contained"
                data-testid="signin-btn"
              >
                {t('form.actions.send')}
              </RoundedButton>
            </Box>
          </Form>
        </FormProvider>
      </Stack>
    </StyledFormRoot>
  );
};
