import { httpClient } from '@/shared/lib/axios';

type SendRegistrationRequestParams = {
  payload: {
    first_name?: string;
    last_name?: string;
    phone: string;
    tin?: string;
    referral_code?: string;
  };
};

export const RegistrationRequestApi = {
  sendRegistrationRequest: (params: SendRegistrationRequestParams) =>
    httpClient.post<void>('registration-request', params.payload),
};
