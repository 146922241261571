import { joinPath } from '../lib';

const ROOT_PATH = '/product-selection';
const MANUAL_SELECTION_PATH = joinPath(ROOT_PATH, 'manual');
const SPECIAL_OFFERS_SELECTION_PATH = joinPath(ROOT_PATH, 'special-offers');

const ORDER_PATH = joinPath(ROOT_PATH, 'order');
const CART_PATH = joinPath(ORDER_PATH, 'cart');
const CHECKOUT_PATH = joinPath(ORDER_PATH, 'checkout');
const PRODUCT_UPDATE_PATH = joinPath(CHECKOUT_PATH, 'product-update');

const AUTO_SELECTION_PATH = joinPath(ROOT_PATH, 'auto');

const FIELD_MATCHING_PATH = joinPath(AUTO_SELECTION_PATH, 'fields');
const BINDING_PATH = joinPath(AUTO_SELECTION_PATH, 'binding');
const BOUND_DRUGS_PATH = joinPath(ROOT_PATH, 'bound-drugs');

export type CartPathState = {
  from: string;
};

export const ProductSelectionPaths = {
  ROOT_PATH,
  MANUAL_SELECTION_PATH,
  SPECIAL_OFFERS_SELECTION_PATH,
  AUTO_SELECTION_PATH,
  BOUND_DRUGS_PATH,
  PRODUCT_UPDATE_PATH,
  ORDER_PATH,
  CART_PATH,
  CHECKOUT_PATH,
  FIELD_MATCHING_PATH,
  BINDING_PATH,
} as const;
