import { forwardRef } from 'react';
import { NumericFormat, type NumericFormatProps, type OnValueChange } from 'react-number-format';
import { useToggle } from 'react-use';
import { IconButton, InputBase, styled, SvgIcon, type InputBaseProps } from '@mui/material';
import { size } from 'lodash';

import { ReactComponent as AddSVG } from '@/shared/assets/icons-2.0/add.svg';
import { ReactComponent as RemoveSVG } from '@/shared/assets/icons-2.0/remove.svg';
import { formatNumberByThousands } from '@/shared/helpers/format.helpers';
import { isSafeNumeric } from '@/shared/helpers/strings.helpers';

type StyledCartCounterProps = {
  disabled?: boolean;
  isFocused?: boolean;
};

export const StyledRoot = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled' && prop !== 'isFocused',
})<StyledCartCounterProps>(
  ({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    width: 'auto',
    minWidth: '100%',
    height: 32,
    padding: theme.spacing(0, 0.5),
    border: theme.shape.divider,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.neutral['100'],

    '& .MuiIconButton-root': {
      color: theme.palette.text.primary,
    },
  }),
  ({ disabled, theme }) =>
    disabled && {
      pointerEvents: 'none',

      '& .MuiIconButton-root, & .MuiInputBase-input': {
        color: theme.palette.neutral[500],
      },
    },
  ({ isFocused, theme }) =>
    isFocused && {
      '.CartCounterBase-InputContainer': {
        backgroundColor: theme.palette.secondary.main,
      },
      '.MuiInputBase-root': {
        color: theme.palette.common.white,
      },
    },
);

const StyledInputContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'size' && prop !== 'isFocused',
})<{ size: number }>(({ size, theme }) => ({
  flexGrow: 1,
  alignSelf: 'stretch',
  position: 'relative',
  minWidth: `${size}ch`,
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadiusHalf,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  ...theme.typography.body2,

  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
})) as typeof InputBase;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: theme.shape.borderRadiusHalf,

  '.MuiTouchRipple-root .MuiTouchRipple-child': {
    borderRadius: 'inherit',
  },
})) as typeof IconButton;

export type CartCounterBaseProps = Pick<InputBaseProps, 'className' | 'inputRef' | 'onFocus' | 'onBlur'> &
  Pick<NumericFormatProps, 'onClick' | 'onKeyDown' | 'isAllowed'> &
  Pick<StyledCartCounterProps, 'disabled'> & {
    value: number | string;
    onIncrease?: () => void;
    onDecrease?: () => void;
    onValueChange?: OnValueChange;
    disableIncrease?: boolean;
    fontColor?: string;
  };

const MIN_INPUT_SIZE = 2;

export const CloudCartCounterBase = forwardRef<HTMLDivElement, CartCounterBaseProps>((props, ref) => {
  const {
    disabled,
    value,
    className,
    inputRef,
    onIncrease,
    onDecrease,
    onValueChange,
    onFocus,
    onBlur,
    onKeyDown,
    onClick,
    disableIncrease = false,
    isAllowed,
    fontColor,
  } = props;

  const valueSize = size(formatNumberByThousands(value));

  const [isFocused, toggleFocused] = useToggle(false);

  return (
    <StyledRoot ref={ref} disabled={disabled} isFocused={isFocused} className={className}>
      <StyledIconButton
        disabled={Number(value) === 0 || disabled}
        onClick={onDecrease}
        data-testid="cart-counter-decrease-btn"
      >
        <SvgIcon component={RemoveSVG} inheritViewBox fontSize="small" />
      </StyledIconButton>

      <StyledInputContainer size={Math.max(valueSize, MIN_INPUT_SIZE)} className="CartCounterBase-InputContainer">
        <NumericFormat
          isAllowed={isAllowed ? isAllowed : ({ value }) => !disabled && isSafeNumeric(value)}
          customInput={StyledInputBase}
          valueIsNumericString
          allowNegative={false}
          inputRef={inputRef}
          value={value}
          decimalScale={0}
          thousandSeparator=" "
          onFocus={event => {
            toggleFocused(true);
            onFocus?.(event);
          }}
          onBlur={event => {
            toggleFocused(false);
            onBlur?.(event);
          }}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onValueChange={onValueChange}
          inputProps={{
            'data-testid': 'cart-counter-input',
            style: {
              color: isFocused ? '#ffffff' : fontColor,
            },
          }}
        />
      </StyledInputContainer>
      <StyledIconButton
        disabled={disabled || disableIncrease}
        onClick={onIncrease}
        data-testid="cart-counter-increase-btn"
      >
        <SvgIcon component={AddSVG} inheritViewBox fontSize="small" />
      </StyledIconButton>
    </StyledRoot>
  );
});

if (import.meta.env.DEV) {
  CloudCartCounterBase.displayName = 'CartCounterBase';
}
