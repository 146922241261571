import { paramsToQueryKey } from '@/shared/lib/react-query';

import type {
  FetchDrugDistributorReportsParams,
  FetchDrugInvoicesParams,
  FetchDrugOrdersParams,
  FetchMarketingOrgParams,
  FetchPlanogramTask,
  FetchPlanogramTasks,
  FetchSalesPlanMarketingOrgParams,
  FetchSalesPlanParams,
  FetchSpecificationActParams,
  FetchSpecificationContractDocumentParams,
  FetchSpecificationInvoiceParams,
  FetchSpecificationParams,
  FetchSpecificationsParams,
} from './dorim-promo.api';

const PREFIX = 'dorim-promo';

export const DorimPromoQueryKeys = {
  root: [PREFIX],

  mainContractDocumentKey: [PREFIX, 'main-contract-document'],
  createMainContractDocumentKey: [PREFIX, 'create-main-contract-document'],
  isSpecificationContractAcceptedKey: [PREFIX, 'is-specification-contract-accepted'],
  getSpecificationContractDocumentKey: (params?: Partial<FetchSpecificationContractDocumentParams>) =>
    paramsToQueryKey([PREFIX, 'specification-contract-document'], params),
  getCreateSpecificationContractDocumentKey: (params?: Partial<FetchSpecificationContractDocumentParams>) =>
    paramsToQueryKey([PREFIX, 'create-specification-contract-document'], params),
  getSpecificationsKey: (params?: FetchSpecificationsParams) => paramsToQueryKey([PREFIX, 'list'], params),

  getSpecificationKey: (params?: FetchSpecificationParams) => paramsToQueryKey([PREFIX, 'view'], params),
  getSpecificationActKey: (params?: FetchSpecificationActParams) =>
    paramsToQueryKey([PREFIX, 'specification-act'], params),
  getSpecificationInvoiceKey: (params?: FetchSpecificationInvoiceParams) =>
    paramsToQueryKey([PREFIX, 'specification-invoice'], params),
  getMarketingOrgKey: (params: FetchMarketingOrgParams) => paramsToQueryKey([PREFIX, 'marketing-org', 'view'], params),
  getDrugOrdersKey: (params?: FetchDrugOrdersParams) => paramsToQueryKey([PREFIX, 'drug-orders'], params),
  getPlanogramTasks: (params: FetchPlanogramTasks) => paramsToQueryKey([PREFIX, 'planogram-tasks'], params),
  getPlanogramTask: (params: FetchPlanogramTask) => paramsToQueryKey([PREFIX, 'planogram-task'], params),
  getDrugDistributorReports: (params?: FetchDrugDistributorReportsParams) =>
    paramsToQueryKey([PREFIX, 'drug-distributor-reports'], params),
  getDrugInvoicesKey: (params?: FetchDrugInvoicesParams) => paramsToQueryKey([PREFIX, 'drug-invoices'], params),
  getSalesPlanKey: (params: Partial<FetchSalesPlanParams>) => paramsToQueryKey([PREFIX, 'sales-plans'], params),
  getSalesPlanMarketingOrgKey: (params?: Partial<FetchSalesPlanMarketingOrgParams>) =>
    paramsToQueryKey([PREFIX, 'sales-plan-products'], params),
} as const;
