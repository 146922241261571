import { Trans } from 'react-i18next';

import { getDorimEnvironment } from '@/shared/helpers/common.helpers';
import {
  BreadcrumbLink,
  ProductSelectionPaths,
  RedirectWithoutAccess,
  type RouteObjectWithBreadcrumbs,
} from '@/shared/lib/react-router';

export const ProductUpdateRoute: RouteObjectWithBreadcrumbs = {
  path: ProductSelectionPaths.PRODUCT_UPDATE_PATH,
  handle: {
    breadcrumb: {
      element: (
        <BreadcrumbLink to={ProductSelectionPaths.PRODUCT_UPDATE_PATH}>
          <Trans ns="product-update-page" i18nKey="title" />
        </BreadcrumbLink>
      ),
    },
  },
  lazy: async () => {
    const { ProductUpdatePage: DistributorsIntegrationPage } = await import(
      /* webpackChunkName: "DistributorsIntegrationPage" */
      './ProductUpdatePage'
    );
    const { isDev } = getDorimEnvironment();

    return {
      Component: () => (
        <RedirectWithoutAccess hasAccess={isDev} to={ProductSelectionPaths.ROOT_PATH}>
          <DistributorsIntegrationPage />
        </RedirectWithoutAccess>
      ),
    };
  },
};
