import { useQuery } from '@tanstack/react-query';

import { DorimPromoApi, type FetchSpecificationInvoiceParams } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';

export const useSpecificationInvoice = (params: FetchSpecificationInvoiceParams) =>
  useQuery({
    queryKey: DorimPromoQueryKeys.getSpecificationInvoiceKey(params),
    queryFn: () => {
      if (!params) return;

      return DorimPromoApi.fetchSpecificationInvoice(params);
    },
    enabled: !!params && !!params.specificationId,
    staleTime: Infinity,
  });
