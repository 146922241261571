import { CircularProgress, Stack, type CircularProgressProps, type StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type LoadingIndicatorProps = {
  slotProps?: {
    container?: StackProps;
    indicator?: CircularProgressProps;
  };
};

const StyledRoot = styled(Stack)({
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
});

export const LoadingIndicator = (props: LoadingIndicatorProps) => (
  <StyledRoot {...props?.slotProps?.container}>
    <CircularProgress disableShrink color="secondary" {...props?.slotProps?.indicator} />
  </StyledRoot>
);
