import { Trans } from 'react-i18next';

import { MainLayout } from '@/layouts/MainLayout';
import { BreadcrumbLink, ProductSelectionPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { CartPageRoute } from './cart/cart-page.route';
import { CheckoutRoute } from './checkout/checkout.route';

export const OrderRoute: RouteObjectWithBreadcrumbs = {
  path: ProductSelectionPaths.ORDER_PATH,
  element: <MainLayout />,
  handle: {
    breadcrumb: {
      element: (
        <BreadcrumbLink to={ProductSelectionPaths.ROOT_PATH}>
          <Trans ns="product-selection-page" i18nKey="layout.title" />
        </BreadcrumbLink>
      ),
    },
  },
  children: [CheckoutRoute, CartPageRoute],
};
