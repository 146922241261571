import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider, Tab, Tabs } from '@mui/material';
import { first } from 'lodash';

import { type AutoSelectionInfo } from '@/api/domains/auto-selection.types';
import { HeaderTabs } from '@/layouts/Header';
import { useActiveAutoSelection } from '@/pages/product-selection/auto/shared/hooks/useActiveAutoSelection';
import { ProductSelectionPaths } from '@/shared/lib/react-router';

import { useProductSelectionLayoutTabs } from './useProductSelectionLayoutTabs';

export const ProductSelectionLayoutTabs = () => {
  const { currentTab, manualTabValue, specialOffersTabValue, autoTabValue, boundDrugsValue } =
    useProductSelectionLayoutTabs();

  const { data: autoSelectionInfo } = useActiveAutoSelection<AutoSelectionInfo>({ select: first });
  const { t } = useTranslation('product-selection-page');
  const autoSelectionId = autoSelectionInfo?.id;

  return (
    <HeaderTabs>
      <Tabs value={currentTab} textColor="primary" indicatorColor="primary" data-testid="product-selection-tabs">
        <Tab
          component={Link}
          to={ProductSelectionPaths.MANUAL_SELECTION_PATH}
          value={manualTabValue}
          label={t('layout.tabs.manual')}
        />
        <Tab
          component={Link}
          to={ProductSelectionPaths.SPECIAL_OFFERS_SELECTION_PATH}
          value={specialOffersTabValue}
          label={t('layout.tabs.special-offers')}
        />
        <Divider orientation="vertical" flexItem sx={{ my: 1, marginRight: 1 }} />
        <Tab
          component={Link}
          to={ProductSelectionPaths.AUTO_SELECTION_PATH}
          value={autoTabValue}
          label={t('layout.tabs.auto-selection')}
        />

        {autoSelectionId && Boolean(autoSelectionInfo.binded_drugs) && (
          <Tab
            component={Link}
            to={ProductSelectionPaths.BOUND_DRUGS_PATH}
            value={boundDrugsValue}
            label={t('layout.tabs.bound-drugs')}
          />
        )}
        {autoSelectionId && <Divider orientation="vertical" flexItem sx={{ m: 1 }} />}
      </Tabs>
    </HeaderTabs>
  );
};
