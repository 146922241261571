import { httpClient } from '@/shared/lib/axios';

import type { PaginatedList, PaginationParams } from '../types/pagination.types';
import type {
  ContractDocument,
  DrugDistributorReport,
  DrugInvoice,
  DrugOrder,
  MarketingOrg,
  PlanogramTask,
  SalesPlanAgreement,
  SalesPlanMarketingOrgDetails,
  SpecificationBase,
  SpecificationMeta,
} from './dorim-promo.types';

export type FetchSpecificationParams = {
  specificationId: number;
};

export type FetchSpecificationActParams = {
  specificationId: number;
};

export type FetchSpecificationInvoiceParams = {
  specificationId: number;
};

export type FetchMarketingOrgParams = {
  specificationId: number;
  marketingOrgId: number;
};

export type FetchSpecificationsParams = PaginationParams;

export type Specifications = PaginatedList<'items', SpecificationBase, SpecificationMeta>;

export type FetchSpecificationContractDocumentParams = {
  specificationId: number;
};

export type Specification = {
  agreement: SpecificationBase | null;
  cashback_sum: number;
  marketing_org_details: MarketingOrg[];
  total_maximum_bonus: number;
  total_minimum_bonus: number;
};

export type SpecificationAct = {
  file_url: string;
};

export type SpecificationInvoice = {
  file_url: string;
};

export type SpecificationSalesPlan = {
  agreement: SalesPlanAgreement | null;
  marketing_org_details: SalesPlanMarketingOrgDetails[];
};

export type SpecificationSalesPlanMarketingOrg = {
  agreement: SalesPlanAgreement | null;
  marketing_org_details: SalesPlanMarketingOrgDetails;
};

export type MarketingOrgResponse = {
  agreement: SpecificationBase | null;
  marketing_org_details: MarketingOrg | null;
};

export type SignMainContractDocumentParams = {
  pkcs7: string;
};

export type SignSpecificationContractDocumentParams = {
  specificationId: number;
  pkcs7: string;
};

export type AcceptSpecificationParams = {
  specificationId: number;
};

export type FetchDrugOrdersParams = {
  agreement_id: number;
  drug_id: number;
};

export type FetchPlanogramTasks = PaginationParams & {
  agreement_id: number;
};

export type FetchPlanogramTask = {
  agreement_id: number;
  task_id: number;
};

export type FetchDrugDistributorReportsParams = {
  agreement_id: number;
  drug_id: number;
};

export type FetchDrugInvoicesParams = {
  agreement_id: number;
  drug_id: number;
};

export type FetchSalesPlanParams = {
  agreement_id: number;
};

export type FetchSalesPlanMarketingOrgParams = {
  agreement_id: number;
  marketing_org_id: number;
};

export const DorimPromoApi = {
  fetchSpecification: async (params: FetchSpecificationParams) => {
    const { data } = await httpClient.get<Specification>(`cashback/agreement/${params.specificationId}`);

    return data;
  },

  fetchSpecificationAct: async (params: FetchSpecificationActParams) => {
    const { data } = await httpClient.get<SpecificationAct>(`cashback/agreement/${params.specificationId}/act`);

    return data;
  },

  fetchSpecificationInvoice: async (params: FetchSpecificationInvoiceParams) => {
    const { data } = await httpClient.get<SpecificationInvoice>(`cashback/agreement/${params.specificationId}/esf`);

    return data;
  },

  fetchMarketingOrg: async (params: FetchMarketingOrgParams) => {
    const { data } = await httpClient.get<MarketingOrgResponse>(
      `cashback/agreement/${params.specificationId}/marketing_org/${params.marketingOrgId}`,
    );

    return data;
  },

  fetchSpecifications: async (params?: FetchSpecificationsParams) => {
    const { data } = await httpClient.get<Specifications>('cashback/agreement', {
      params,
    });

    return data;
  },

  confirmReceivingCashbackBonus: (specificationId: number) =>
    httpClient.patch<void>(`cashback/agreement/${specificationId}/accept-payment`),

  fetchMainContractDocument: async () => {
    const { data } = await httpClient.get<ContractDocument>('pharmacy/document-agreements');

    return data;
  },

  createMainContractDocument: async () => {
    const { data } = await httpClient.post<ContractDocument>('pharmacy/document-agreements');

    return data;
  },

  fetchSpecificationContractDocument: async (params: FetchSpecificationContractDocumentParams) => {
    const { data } = await httpClient.get<ContractDocument>(`cashback/agreement/${params.specificationId}/document`);

    return data;
  },

  createSpecificationContractDocument: async (params: FetchSpecificationContractDocumentParams) => {
    const { data } = await httpClient.post<ContractDocument>(`cashback/agreement/${params.specificationId}/document`);

    return data;
  },

  signMainContract: async (params: SignMainContractDocumentParams) =>
    httpClient.post('cashback/agreement/sign-main-agreement', params),

  signSpecificationContract: async (params: SignSpecificationContractDocumentParams) => {
    const { specificationId, pkcs7 } = params;

    return httpClient.post(`cashback/agreement/${specificationId}/sign`, { pkcs7 });
  },

  acceptSpecificationContract: async (params: AcceptSpecificationParams) => {
    const { specificationId } = params;

    return httpClient.patch(`cashback/agreement/${specificationId}/accept`);
  },

  fetchDrugOrders: async (params: FetchDrugOrdersParams) => {
    const { agreement_id, drug_id } = params;

    const { data } = await httpClient.get<PaginatedList<'items', DrugOrder>>(
      `cashback/agreement/${agreement_id}/statistics/${drug_id}/orders`,
    );

    return data;
  },
  fetchDrugInvoices: async (params: FetchDrugInvoicesParams) => {
    const { agreement_id, drug_id } = params;

    const { data } = await httpClient.get<PaginatedList<'items', DrugInvoice>>(
      `cashback/agreement/${agreement_id}/statistics/${drug_id}`,
    );

    return data;
  },

  fetchPlanogramTasks: async ({ agreement_id, ...params }: FetchPlanogramTasks) => {
    const { data } = await httpClient.get<PaginatedList<'items', PlanogramTask>>(
      `cashback/agreements/${agreement_id}/display-tasks`,
      { params },
    );

    return data;
  },

  fetchPlanogramTask: async ({ agreement_id, task_id }: FetchPlanogramTask) => {
    const { data } = await httpClient.get<PlanogramTask>(
      `cashback/agreements/${agreement_id}/display-tasks/${task_id}`,
    );

    return data;
  },

  fetchDrugDistributorReports: async (params: FetchDrugDistributorReportsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', DrugDistributorReport>>(
      `cashback/agreement/${params.agreement_id}/statistics/${params.drug_id}/distributor-reports`,
    );

    return data;
  },

  fetchSalesPlan: async ({ agreement_id }: FetchSalesPlanParams) => {
    const { data } = await httpClient.get<SpecificationSalesPlan>(`cashback/agreement/${agreement_id}/sales`);

    return data;
  },

  fetchSalesPlanMarketingOrg: async ({ agreement_id, marketing_org_id }: FetchSalesPlanMarketingOrgParams) => {
    const { data } = await httpClient.get<SpecificationSalesPlanMarketingOrg>(
      `cashback/agreement/${agreement_id}/sales/${marketing_org_id}`,
    );

    return data;
  },
};
