import { Trans } from 'react-i18next';

import { BreadcrumbLink, ProductSelectionPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const CartPageRoute: RouteObjectWithBreadcrumbs = {
  path: ProductSelectionPaths.CART_PATH,
  handle: {
    breadcrumb: {
      element: (
        <BreadcrumbLink to={ProductSelectionPaths.CART_PATH}>
          <Trans ns="cart-page" i18nKey="breadcrumb" />
        </BreadcrumbLink>
      ),
    },
  },
  lazy: async () => {
    const { CartPage } = await import(
      /* webpackChunkName: "CartPage" */
      './CartPage'
    );

    return {
      Component: () => <CartPage />,
    };
  },
};
