import { Suspense } from 'react';
import { Navigate, RouterProvider } from 'react-router';
import { createBrowserRouter, useRouteError } from 'react-router-dom';

import { ProgressBar } from '@/components/ProgressBar';
import { AuthGuard } from '@/guards/AuthGuard';
import { GuestGuard } from '@/guards/GuestGuard';
import { NecessityFileGuard } from '@/guards/NecessityFileGuard';
import { SignUpGuard } from '@/guards/SignUpGuard';
import { AuthLayout } from '@/layouts/AuthLayout';
import { AutoSelectionLayout } from '@/layouts/AutoSelectionLayout';
import { CloudCartRootLayout } from '@/layouts/CloudCartRootLayout';
import { MainLayout } from '@/layouts/MainLayout';
import { ProductSelectionLayout } from '@/layouts/ProductSelectionLayout';
import { ProfileLayout } from '@/layouts/ProfileLayout';
import { ReferralLayout } from '@/layouts/ReferralLayout';
import { SettingsLayout } from '@/layouts/Settings';
import { AccountPage } from '@/pages/auth/reset-password/AccountPage';
import { BanPage } from '@/pages/auth/reset-password/BanPage';
import { NewPasswordPage } from '@/pages/auth/reset-password/NewPasswordPage';
import { SuccessPage } from '@/pages/auth/reset-password/SuccessPage';
import { VerifyPhonePage as ResetPasswordVerifyPhoneForm } from '@/pages/auth/reset-password/VerifyPhonePage';
import SignInPage from '@/pages/auth/sign-in';
import { CompanyForm } from '@/pages/auth/sign-up/forms/CompanyForm';
import { CreatePasswordForm } from '@/pages/auth/sign-up/forms/CreatePasswordForm';
import { PharmacyForm } from '@/pages/auth/sign-up/forms/PharmacyForm';
import { RegistrationForm } from '@/pages/auth/sign-up/forms/RegistrationForm';
import { VerifyPhoneForm as SignUpVerifyPhoneForm } from '@/pages/auth/sign-up/forms/VerifyPhoneForm';
import { SignUpBanPage } from '@/pages/auth/sign-up/SignUpBanPage';
import { SignUpFinalPage } from '@/pages/auth/sign-up/SignUpFinalPage';
import CreateTelegramGroupPage from '@/pages/create-telegram-group';
import { DorimFinanceRoute } from '@/pages/dorim-finance';
import { DorimPromoRoute } from '@/pages/dorim-promo';
import OrdersPage from '@/pages/orders';
import {
  NecessityListFieldMatchingPage,
  OrderRoute,
  ProductSelectionAutoPage,
  ProductSelectionBoundDrugsPage,
  ProductSelectionManualPage,
  ProductSelectionSpecialOffersPage,
} from '@/pages/product-selection';
import { ProductSelectionAutoBindingPage } from '@/pages/product-selection/auto/binding';
import { EntityInfoPage, PersonalInfoPage } from '@/pages/profile';
import ReferralRequestCompletedPage from '@/pages/referral/referral-request-completed/ReferralRequestCompletedPage';
import ReferralPage from '@/pages/referral/ReferralPage';
import { RequirementCalculationRoute } from '@/pages/requirements-calculation';
import {
  DistributorsSettingsPage,
  ExpirationDatesSettingsPage,
  PharmacySettingsPage,
  UsersSettingsPage,
} from '@/pages/settings';
import {
  AuthPaths,
  CreateTelegramGroupPaths,
  OrdersPaths,
  ProductSelectionPaths,
  ProfilePaths,
  ReferralPaths,
  SettingsPaths,
} from '@/shared/lib/react-router';

import { Providers } from './Providers';
import { Root } from './Root';

/**
 * @link https://github.com/remix-run/react-router/discussions/10166
 */
const BubbleError = () => {
  const error = useRouteError();

  if (error) throw error;
  return null;
};

const router = createBrowserRouter([
  {
    element: (
      <Providers>
        <Root />
      </Providers>
    ),
    errorElement: <BubbleError />,
    children: [
      {
        path: ReferralPaths.ROOT_PATH,
        element: (
          <GuestGuard>
            <ReferralLayout type="sign-in" />
          </GuestGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <ReferralPage />
              </Suspense>
            ),
          },
          {
            path: ReferralPaths.REFERRAL_REQUEST_COMPLETED_PATH,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <ReferralRequestCompletedPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: AuthPaths.SIGN_IN.ROOT,
        element: (
          <GuestGuard>
            <AuthLayout type="sign-in" />
          </GuestGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignInPage />
              </Suspense>
            ),
          },
          {
            path: AuthPaths.SIGN_IN.BAN,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignUpBanPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: AuthPaths.RESET_PASSWORD.ROOT,
        element: (
          <GuestGuard>
            <AuthLayout type="reset-password" />
          </GuestGuard>
        ),
        children: [
          { index: true, element: <Navigate replace to={AuthPaths.RESET_PASSWORD.ACCOUNT} /> },
          {
            path: AuthPaths.RESET_PASSWORD.ACCOUNT,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <AccountPage />
              </Suspense>
            ),
          },
          {
            path: AuthPaths.RESET_PASSWORD.VERIFY_PHONE,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <ResetPasswordVerifyPhoneForm />
              </Suspense>
            ),
          },
          {
            path: AuthPaths.RESET_PASSWORD.NEW_PASSWORD,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <NewPasswordPage />
              </Suspense>
            ),
          },
          {
            path: AuthPaths.RESET_PASSWORD.SUCCESS,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SuccessPage />
              </Suspense>
            ),
          },
          {
            path: AuthPaths.RESET_PASSWORD.BAN,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <BanPage />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: AuthPaths.SIGN_UP.ROOT,
        element: <AuthLayout type="sign-up" />,
        children: [
          { index: true, element: <Navigate replace to={AuthPaths.SIGN_UP.DEFAULT} /> },
          {
            path: AuthPaths.SIGN_UP.REGISTRATION,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignUpGuard>
                  <RegistrationForm />
                </SignUpGuard>
              </Suspense>
            ),
          },
          {
            path: AuthPaths.SIGN_UP.VERIFY_PHONE,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignUpGuard>
                  <SignUpVerifyPhoneForm />
                </SignUpGuard>
              </Suspense>
            ),
          },
          {
            path: AuthPaths.SIGN_UP.CREATE_PASSWORD,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignUpGuard>
                  <CreatePasswordForm />
                </SignUpGuard>
              </Suspense>
            ),
          },
          {
            path: AuthPaths.SIGN_UP.COMPANY_INFO,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignUpGuard>
                  <CompanyForm />
                </SignUpGuard>
              </Suspense>
            ),
          },
          {
            path: AuthPaths.SIGN_UP.PHARMACY_INFO,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignUpGuard>
                  <PharmacyForm />
                </SignUpGuard>
              </Suspense>
            ),
          },
          {
            path: AuthPaths.SIGN_UP.BAN,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignUpBanPage />
              </Suspense>
            ),
          },
          {
            path: AuthPaths.SIGN_UP.FINAL,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <SignUpFinalPage />
              </Suspense>
            ),
          },
          { path: '*', element: <Navigate replace to={AuthPaths.SIGN_UP.DEFAULT} /> },
        ],
      },

      { path: AuthPaths.SIGN_UP.OLD_ROOT, element: <Navigate replace to={AuthPaths.SIGN_UP.ROOT} /> },

      { path: '/', element: <Navigate replace to={ProductSelectionPaths.MANUAL_SELECTION_PATH} /> },
      RequirementCalculationRoute,
      {
        path: ProductSelectionPaths.ROOT_PATH,
        element: (
          <AuthGuard>
            <CloudCartRootLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: ProductSelectionPaths.ROOT_PATH,
            element: <ProductSelectionLayout />,
            children: [
              {
                index: true,
                element: <Navigate replace to={ProductSelectionPaths.MANUAL_SELECTION_PATH} />,
              },
              {
                path: ProductSelectionPaths.MANUAL_SELECTION_PATH,
                element: (
                  <Suspense fallback={<ProgressBar />}>
                    <ProductSelectionManualPage />
                  </Suspense>
                ),
              },
              {
                path: ProductSelectionPaths.SPECIAL_OFFERS_SELECTION_PATH,
                element: (
                  <Suspense fallback={<ProgressBar />}>
                    <ProductSelectionSpecialOffersPage />
                  </Suspense>
                ),
              },
              {
                path: ProductSelectionPaths.AUTO_SELECTION_PATH,
                element: (
                  <Suspense fallback={<ProgressBar />}>
                    <ProductSelectionAutoPage />
                  </Suspense>
                ),
              },
              {
                path: ProductSelectionPaths.BOUND_DRUGS_PATH,
                element: (
                  <Suspense fallback={<ProgressBar />}>
                    <ProductSelectionBoundDrugsPage />
                  </Suspense>
                ),
              },
            ],
          },

          OrderRoute,

          {
            path: ProductSelectionPaths.AUTO_SELECTION_PATH,
            element: (
              <NecessityFileGuard>
                <AutoSelectionLayout />
              </NecessityFileGuard>
            ),
            children: [
              {
                path: ProductSelectionPaths.FIELD_MATCHING_PATH,
                element: (
                  <Suspense fallback={<ProgressBar />}>
                    <NecessityListFieldMatchingPage />
                  </Suspense>
                ),
              },
              {
                path: ProductSelectionPaths.BINDING_PATH,
                element: (
                  <Suspense fallback={<ProgressBar />}>
                    <ProductSelectionAutoBindingPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        path: OrdersPaths.ROOT,
        element: (
          <AuthGuard>
            <MainLayout />
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <OrdersPage />
              </Suspense>
            ),
          },
        ],
      },

      DorimFinanceRoute,
      DorimPromoRoute,

      {
        path: ProfilePaths.ROOT_PATH,
        element: (
          <AuthGuard>
            <ProfileLayout />
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: <Navigate replace to={ProfilePaths.PERSONAL_INFO_PATH} />,
          },
          {
            path: ProfilePaths.PERSONAL_INFO_PATH,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <PersonalInfoPage />
              </Suspense>
            ),
          },
          {
            path: ProfilePaths.ENTITY_INFO_PATH,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <EntityInfoPage />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: SettingsPaths.ROOT,
        element: (
          <AuthGuard>
            <Suspense fallback={<ProgressBar />}>
              <SettingsLayout />
            </Suspense>
          </AuthGuard>
        ),
        children: [
          { index: true, element: <Navigate replace to={SettingsPaths.DISTRIBUTORS} /> },
          {
            path: SettingsPaths.DISTRIBUTORS,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <DistributorsSettingsPage />
              </Suspense>
            ),
          },
          {
            path: SettingsPaths.EXPIRY_DATES,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <ExpirationDatesSettingsPage />
              </Suspense>
            ),
          },
          {
            path: SettingsPaths.PHARMACIES,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <PharmacySettingsPage />
              </Suspense>
            ),
          },
          {
            path: SettingsPaths.USERS,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <UsersSettingsPage />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: CreateTelegramGroupPaths.ROOT,
        element: <CreateTelegramGroupPage />,
      },

      {
        path: '*',
        element: <Navigate replace to="/" />,
      },
    ],
  },
]);

export const Router = () => <RouterProvider router={router} />;
