import { type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const CheckoutPageRoute: RouteObjectWithBreadcrumbs = {
  index: true,

  lazy: async () => {
    const { CheckoutPage } = await import(
      /* webpackChunkName: "CheckoutPage" */
      './CheckoutPage.async'
    );

    return {
      Component: () => <CheckoutPage />,
    };
  },
};
