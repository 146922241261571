import { Box, Container, css, Stack, styled, Typography } from '@mui/material';

import backgroundSRC from '@/shared/assets/image/registration/background.png';
import headerMobileBackground from '@/shared/assets/image/registration/mob_header_bg.png';

export const StyledRoot = styled(Stack)`
  width: 100%;
  min-height: 100%;
  background: url(${backgroundSRC}) no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      justify-content: space-between;
    }
    ,
    ${theme.breakpoints.down('tablet')} {
      align-items: center;
    }
  `}
`;

export const StyledContainer = styled(Container)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('tablet')} {
      min-height: 100%;
      padding: 16px;
      flex: 1;
      display: flex;
    }
  `}
`;

export const StyledContentOuter = styled('div')`
  position: relative;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      flex-flow: column;
      row-gap: 60px;
      align-items: start;
    }
    ${theme.breakpoints.down('tablet')} {
      flex: 1;
      row-gap: 40px;
    }
  `}
`;

export const StyledOvalContainer = styled(Box)`
  border-radius: 200px;
  border: 1px solid #fff;
  background: rgba(193, 231, 237, 0.6);
  backdrop-filter: blur(3.5px);

  height: 400px;

  display: flex;

  position: absolute;
  left: 0;
  right: 0;
  padding-left: 140px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      position: relative;
      padding: 40px 16px 72px;
      flex: 1 1;
      width: 100%;
      margin-top: 80px;
    }

    ${theme.breakpoints.down('tablet')} {
      background: url(${headerMobileBackground}) no-repeat center;
      border-radius: 0;
      backdrop-filter: none;
      border: none;
      height: auto;
      position: relative;
      padding: 16px;
      margin-top: 0;
      flex: 0 0;
    }
  `}
`;

export const StyledContent = styled('div')`
  width: 100%;
  padding-left: 140px;
  padding-right: 140px;
  border-radius: 16px;
  display: flex;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      align-items: center;
      padding-left: 0;
      padding-right: 0;
      flex: 1 1 100%;
      justify-content: center;
    }
    ${theme.breakpoints.down('tablet')} {
      background-color: #ffffff;
      margin-top: 0;
      flex: 0 0;
    }
  `}
`;

export const StyledFormWrapper = styled('div')`
  min-width: 400px;
  min-height: 614px;
  justify-content: center;
  max-width: 920px;
  position: relative;
  z-index: 2;
  margin-left: auto;
  display: flex;
  flex-flow: column;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin-left: 0;
      min-height: 0;
    }

    ${theme.breakpoints.down('tablet')} {
      min-width: 0;
      min-height: 0;
    }
  `}
`;

export const StyledFooter = styled(Container)`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 16px 16px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      position: relative;
      padding: 34px;
      height: auto;
      justify-content: center;
    }

    ${theme.breakpoints.down('tablet')} {
      flex-direction: column-reverse;
      align-items: center;
      background: #ffffff;
      padding: 24px;
    }
  `}
`;

export const StyledSupportSection = styled('div')`
  ${({ theme }) => css`
    ${theme.breakpoints.between('tablet', 'md')} {
      position: absolute;
      right: 60px;
      bottom: 16px;
    }
  `}
`;

export const StyledCopyright = styled(Typography)`
  color: #9aa7b5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;
