import { useQuery } from '@tanstack/react-query';

import { DorimPromoApi, type FetchSpecificationActParams } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';

export const useSpecificationAct = (params: FetchSpecificationActParams) =>
  useQuery({
    queryKey: DorimPromoQueryKeys.getSpecificationActKey(params),
    queryFn: () => {
      if (!params) return;

      return DorimPromoApi.fetchSpecificationAct(params);
    },
    enabled: !!params && !!params.specificationId,
    staleTime: Infinity,
  });
