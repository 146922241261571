import { lazy } from 'react';

export const ProductUpdatePage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ProductUpdatePage" */
      './ProductUpdatePage'
    ),
);
