import { httpClient } from '@/shared/lib/axios';

import type { CloudCartEntrySource } from './cloud-carts.constants';
import type {
  CheckoutCloudCart,
  CloudCart,
  CloudCartDistributor,
  LatestCloudCartUpdate,
  UpdatedCheckoutCloudCartItem,
  UpdatingDistributorInfo,
} from './cloud-carts.types';

export type FetchCloudCartParams = {
  cartId: number;
};

export type FetchCheckoutCloudCartParams = {
  cartId: number;
};

export type UpdateCloudCartEntryParams = {
  cartId: number;
  payload: {
    offer_id: number;
    quantity: number;
    source?: CloudCartEntrySource;
  };
};

export type DeleteCloudCartEntriesParams = {
  cartId: number;
  payload: {
    offer_ids: number[];
  };
};

export type ClearCloudCartParams = {
  cartId: number;
};

export type UpdateCheckoutCloudCartParams = {
  cartId: number;
  distributorId: number;
  distributor: CloudCartDistributor;
};

type ApplyCheckoutCloudCartUpdatesItemsOffer = {
  offer_id: number;
  count: number;
  distributor_id: number;
  distributor_code: string;
  drug_id: number;
  name: string;
  maker_name: string;
  expire_date: string;
  payment_percent: number;
  payment_delay: number;
  price: number;
  price_with_vat: number;
};

type ApplyCheckoutCloudCartUpdatesItems = {
  old_offer_id: number;
  new_offers: ApplyCheckoutCloudCartUpdatesItemsOffer[];
};

export type ApplyCheckoutCloudCartUpdatesParams = {
  cartId: number;
  payload: {
    items: ApplyCheckoutCloudCartUpdatesItems[];
  };
};

const transformUpdatedCheckoutCloudCartItem = (
  distributorInfo: UpdatedCheckoutCloudCartItem,
): UpdatingDistributorInfo => {
  return {
    recordStatusId: distributorInfo.record_status_id,
    items: distributorInfo.items.map(item => {
      return {
        drug: {
          id: item.drug.id,
          fullName: item.drug.full_name,
          maker: item.drug.maker,
          licenseHolder: item.drug.license_holder,
        },
        newOffers: item.new_offers.map(new_offer => {
          return {
            availableToOrder: new_offer.available,
            count: new_offer.count,
            offer: {
              distributor: item.distributor,
              dateExpire: new_offer.expire_date,
              discount: 0,
              discountWithVat: 0,
              id: new_offer.offer_id,
              paymentPercent: new_offer.payment_percent,
              paymentDelay: new_offer.payment_delay,
              price: new_offer.origin_price,
              priceDiscount: new_offer.origin_price_with_vat,
              priceWithVat: new_offer.origin_price_with_vat,
              distributorCode: new_offer.distributor_code,
            },
          };
        }),
        oldOffer: {
          availableToOrder: item.old_offer.available,
          count: item.old_offer.count,
          offer: {
            distributor: item.distributor,
            dateExpire: item.old_offer.expire_date,
            discount: 0,
            discountWithVat: 0,
            id: item.old_offer.offer_id,
            paymentPercent: item.old_offer.payment_percent,
            paymentDelay: item.old_offer.payment_delay,
            price: item.old_offer.origin_price,
            priceDiscount: item.old_offer.origin_price_with_vat,
            priceWithVat: item.old_offer.origin_price_with_vat,
            distributorCode: item.old_offer.distributor_code,
          },
        },
      };
    }),
  };
};

export const CloudCartsApi = {
  fetchLatestCloudCartUpdate: async () => {
    const { data } = await httpClient.post<LatestCloudCartUpdate>('cart/get-update');

    return data;
  },

  fetchCloudCart: async (params: FetchCloudCartParams) => {
    const { data } = await httpClient.get<CloudCart>(`cart/${params.cartId}`);

    return data;
  },

  fetchCheckoutCloudCart: async (params: FetchCheckoutCloudCartParams) => {
    const { data } = await httpClient.get<CheckoutCloudCart>(`cart/${params.cartId}/distributor-items`);

    return data;
  },

  updateCheckoutCloudCart: async (params: UpdateCheckoutCloudCartParams): Promise<UpdatingDistributorInfo> => {
    const { data } = await httpClient.post<UpdatedCheckoutCloudCartItem>('cart/update-distributor-items', {
      cart_id: params.cartId,
      distributor_id: params.distributorId,
    });

    return transformUpdatedCheckoutCloudCartItem(data);
  },

  applyCheckoutCloudCartUpdates: async (params: ApplyCheckoutCloudCartUpdatesParams) => {
    await httpClient.post<void>(`cart/${params.cartId}/apply-updates`, params.payload);
  },

  updateCloudCartEntry: (params: UpdateCloudCartEntryParams) =>
    httpClient.post<void>(`cart/${params.cartId}/items`, params.payload),

  deleteCloudCartEntries: async (params: DeleteCloudCartEntriesParams) =>
    httpClient.delete<void>(`cart/${params.cartId}/items`, { data: params.payload }),

  clearCloudCart: async (params: ClearCloudCartParams) => httpClient.delete<void>(`cart/${params.cartId}/all-items`),
};
