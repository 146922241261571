import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { SupportButton } from '@/components/SupportButton';
import { SupportLinks } from '@/components/SupportLinks';
import { PageContainerBase } from '@/layouts/Page';
import { ReferralWelcome } from '@/pages/auth/components/Welcome';
import { useResponsive } from '@/shared/lib/mui';

import {
  StyledContainer,
  StyledContent,
  StyledContentOuter,
  StyledCopyright,
  StyledFooter,
  StyledFormWrapper,
  StyledOvalContainer,
  StyledRoot,
  StyledSupportSection,
} from './styles';

type AuthLayoutType = 'sign-in' | 'sign-up' | 'reset-password';

export type AuthLayoutProps = {
  type?: AuthLayoutType;
};

export const ReferralLayout = ({ type }: AuthLayoutProps) => {
  const isMobile = useResponsive('down', 'tablet');
  const { t } = useTranslation([`auth-${type}`, 'auth-page']);

  const title = type ? t('title', { ns: `auth-${type}` }) : '';
  const currentYear = new Date().getFullYear();

  return (
    <PageContainerBase title={title}>
      <StyledRoot>
        <StyledContainer>
          <StyledContentOuter>
            <StyledOvalContainer>
              <ReferralWelcome />
            </StyledOvalContainer>

            <StyledContent>
              <StyledFormWrapper>
                <Outlet />
              </StyledFormWrapper>
            </StyledContent>
          </StyledContentOuter>
        </StyledContainer>

        <StyledFooter>
          <StyledCopyright>
            {t(`Dorim © ${currentYear}`)} {t('layout.copyright', { ns: 'auth-page' })}
          </StyledCopyright>
          <StyledSupportSection>{isMobile ? <SupportLinks /> : <SupportButton />}</StyledSupportSection>
        </StyledFooter>
      </StyledRoot>
    </PageContainerBase>
  );
};
