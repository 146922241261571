import { Suspense } from 'react';
import { Trans } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { ProgressBar } from '@/components/ProgressBar';
import { AuthGuard } from '@/guards/AuthGuard';
import { CheckoutCloudCartGuard } from '@/guards/cloud-cart-guards';
import { BreadcrumbLink, ProductSelectionPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { CheckoutPageRoute } from './checkout-page.route';
import { ProductUpdateRoute } from './product-update/product-update.route';

export const CheckoutRoute: RouteObjectWithBreadcrumbs = {
  path: ProductSelectionPaths.CHECKOUT_PATH,
  element: (
    <AuthGuard>
      <CheckoutCloudCartGuard>
        <Suspense fallback={<ProgressBar />}>
          <Outlet />
        </Suspense>
      </CheckoutCloudCartGuard>
    </AuthGuard>
  ),
  handle: {
    breadcrumb: {
      element: (
        <BreadcrumbLink to={ProductSelectionPaths.CHECKOUT_PATH}>
          <Trans i18nKey="breadcrumb" ns="checkout-page" />
        </BreadcrumbLink>
      ),
    },
  },
  children: [CheckoutPageRoute, ProductUpdateRoute],
};
